import { Injectable } from "@angular/core";

@Injectable()
export class StorageService {
  private storage: any;

  constructor() {
    this.storage = localStorage;
  }
  public store(key: string, value: any) {
    this.storage.setItem(key, JSON.stringify(value));
  }

  public retrieve(key: any) {
    var item = this.storage.getItem(key);
    if (item && item !== "undefined") {
      return JSON.parse(this.storage.getItem(key));
    }
    return;
  }
}
