import { Injectable } from "@angular/core";

@Injectable()
export class Configuration {
  private environment: number = 2;
  private version: string = "2.1.4";
  public URL_BASE: string;
  public URL_PRTG: string =
    "http://200.73.4.85/chart.png?type=graph&width=1048&height=400&username=clienteRegional&password=Cliente123&graphid=2";

  constructor() {
    switch (this.environment) {
      case 1: // LOCAL
        this.URL_BASE = "http://localhost:4900/api";
        break;
      case 2: // SERVER
        this.URL_BASE = "https://radioregional.megatime.cl/api";
        break;
      case 3: // Staging
        this.URL_BASE = "http://34.235.250.105/api";
    }
  }
}
