import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AccessComponent } from './components/access/access.component';
import { AppComponent } from './app.component';

import { RoutingModule } from './app.routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// ANGULAR MATERIAL
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';

import {MAT_DATE_LOCALE, MatPaginatorIntl} from '@angular/material';
import { CustomMatPaginatorIntl } from './angular-material-custom/paginator_intl';
import { HttpClientModule } from '@angular/common/http';

// Services 
import { AccessService } from './services/access.service';
import { Configuration } from './app.config';
import { StorageService } from './services/storage.service';

@NgModule({
  declarations: [
    AppComponent,
    AccessComponent
  ],
  imports: [
    NgbModule.forRoot(),
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    RoutingModule,
    FormsModule, 
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatProgressBarModule
  ],
  providers: [AccessService, Configuration, StorageService,  { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }, { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl} ],
  bootstrap: [AppComponent]
})

export class AppModule { }
