import {MatPaginatorIntl} from '@angular/material';
import {Injectable} from '@angular/core';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  constructor() {
    super()
    this.itemsPerPageLabel = 'Elementos por página';
    this.nextPageLabel     = 'Siguiente';
    this.previousPageLabel = 'Anterior';
  }
}