import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { AccessService } from "../../services/access.service";
import { StorageService } from "../../services/storage.service";

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: "app-access",
  templateUrl: "./access.component.html",
  styleUrls: ["./access.component.scss"],
})
export class AccessComponent implements OnInit {
  isLoading: boolean = false;
  matcher = new MyErrorStateMatcher();
  loginForm: FormGroup;

  errorMSG: string = "";
  constructor(
    private _router: Router,
    private _accessService: AccessService,
    private _storageService: StorageService,
    private _fb: FormBuilder
  ) {
    this.loginForm = this._fb.group({
      mail: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required]],
    });
  }

  ngOnInit() {}

  login() {
    if (!this.isLoading) {
      this.isLoading = true;
      this.errorMSG = "";
      setTimeout(() => {
        this._accessService
          .login(
            this.loginForm.controls["mail"].value,
            this.loginForm.controls["password"].value
          )
          .subscribe(
            (res: any) => {
              if (res.ok) {
                this._storageService.store("token", res.token);
                this._storageService.store("userInfo", {
                  username: res.username,
                  cliente: res.cliente,
                });
                this._router.navigate(["system"]);
              } else {
                this.errorMSG = res.message;
                this.isLoading = false;
              }
            },
            (err: any) => {
              this.errorMSG = "";
            },
            () => {}
          );
      }, 1500);
    }
  }
}
