import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccessComponent } from './components/access/access.component';


const routes: Routes = [
  {
    path: '',
    component : AccessComponent
  },
  {
    path: 'system',
    loadChildren : './components/system/system.module#SystemModule'
  }
]; 

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class RoutingModule { }